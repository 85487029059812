import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getSecureAuthCookie, setDataDogUser } from '../login/helpers';
import createStyles from '@guestyci/foundation/createStyles';
import { Col, Spinner } from '@guestyci/foundation';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const useStyles = createStyles(() => ({
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
  },
  text: {
    textAlign: 'center',
  },
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 35,
    height: 35,
  },
}));

export default function Impersonation() {
  const styles = useStyles();

  const [error, setError] = useState(undefined);

  const query = useQuery();
  const token = query.get('token');

  useEffect(() => {
    const handleImpersonation = async () => {
      if (token) {
        window.localStorage.clear();
        window.localStorage.setItem('token', JSON.stringify(token));
        try {
          await getSecureAuthCookie();
          await setDataDogUser(true);
          window.location.href = '/';
        } catch (e) {
          setError(e.message);
        }
      }
    };
    handleImpersonation();
  }, [token]);

  return (
    <Col className={styles.background}>
      {error || (
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      )}
    </Col>
  );
}
